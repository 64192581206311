import { useState } from 'react';
import PlayersTable from './PlayersTable';


function Search({initPlayers, action, back}) {

  const [searchPlayers, setSearchPlayers] = useState(initPlayers);


  const initSearchState = {search: ''};
  const [inputs, setInputs] = useState(initSearchState);
  
  const handleSearch = (e) => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  const buildTable = (event) => {
    event.preventDefault(); // Prevent default form submission
    setSearchPlayers(prevArray => [...prevArray, inputs.search]);
    setInputs(initSearchState);
  }

   const removePlayer = (name) => {
    const tmpPlayers = searchPlayers ? searchPlayers.filter((player) => player !== name) : [];
    setSearchPlayers(tmpPlayers);
  }
 
  return (
    <>
      <form onSubmit={buildTable}>
        <div className='search-bar-container'>
          <input autoFocus
            name="search" 
            type="search" 
            onChange={handleSearch} 
            placeholder="Player Full Name" 
            value={inputs.search} 
            className="search-bar"
          />   
          <i className="material-icons search-icon" onClick={buildTable}>search</i>

        </div>
      </form>
      {searchPlayers && searchPlayers.length > 0 &&
        <>
          <PlayersTable players={searchPlayers} remove={removePlayer}/>
          <div className="form-button-group">
            <div className='form-button' onClick={back}>Cancel</div> 
            <div className='form-button primary' onClick={() => action(searchPlayers)}>Check Out</div>
          </div>
        </>
      }
    </>
      
  );
}


export default Search;
