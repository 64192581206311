import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';

import TeamView from './TeamView';
import OrderMain from './Orders/OrderMain';
import ViewOrders from './Admin/ViewOrders';


function App() {
  return (
    <div className='app'>
      <Router>
        <Routes>
          <Route exact path="/" element={ <TeamView/>} />
          <Route path="/orders/" element={<OrderMain/>} />
          <Route path="/orders/:orderId" element={<OrderMain />} />
          <Route path="/admin/" element={< ViewOrders/>} />
          <Route path="/*" element={ <TeamView/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
