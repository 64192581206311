import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_APP_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
//const listRef = ref(storage, 'gs://all-the-spots.appspot.com/2023_03_07/esA4x8SGl36czsfciAIy/');


export  {
   db as default, 
   analytics,
   storage,
}


// Initialize Firebase
//const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
//const analytics = getAnalytics(app);