// util helper methods go here... 

export const utilMethods = {
  
  customLog: (message) => {
    if (process.env.REACT_APP_DEBUG === 'dev') {
        console.log(message);
    }
  },

  formatDate: (dateStr) => {
    if (dateStr) {
      let d = new Date();
      if (dateStr instanceof Date) {
        d = new Date(dateStr);
      } else {
        d = new Date(dateStr.toDate());
      }
      //date.toDate()).toDateString()
      return (d.getMonth() + 1) + 
        '-' + d.getDate() + 
        '-' +  d.getFullYear() +
        ' ' + d.getHours() +
        ':' + d.getMinutes();
    } 
  },

}