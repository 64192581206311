import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Search from './Search';
import CheckOut from './CheckOut';
import Done from './Done';

import { firestoreMethods } from '../firebase/firestoreMethods';


function OrderMain() {

  const navigate = useNavigate();


  const [players, setPlayers] = useState([]);
  const [orderName, setOrderName] = useState([]);

  const [flow, setFlow] = useState("SEARCH");

  const handleCheckout = (players) => {
    setPlayers(players)
    setFlow("CHECKOUT")
  }

  const handleSubmit = async (name) => {
    setOrderName(name)
    const tmpOrder = {
      ref: Math.floor(Math.random() * 9000) + 1000,
      for: name, 
      players: players,
      date: new Date(),
      status: "NEW" 
    }
    console.log(tmpOrder);
    // SAVE ORDER 
    await firestoreMethods.addOrder(tmpOrder);
    setFlow("DONE")
  }

  const handleDoAnother = () => {
    setPlayers([])
    setFlow("SEARCH")
  }

  const removePlayer = (name) => {
    const tmpPlayers = players ? players.filter((player) => player !== name) : [];
    setPlayers(tmpPlayers);
    if (tmpPlayers.length === 0) {
      setFlow("SEARCH")
    }
  }
  
  if (!flow) return null;
  return (
    <>
      <div className='order-container'>
        <div className='landing-title'>TEAM TILES</div>
      
       {(flow === 'SEARCH') && 
        <Search 
          initPlayers={players} 
          action={handleCheckout}
          back={() => navigate(-1)}
        />
       }
       {(flow === 'CHECKOUT') && 
        <CheckOut 
          players={players} 
          action={handleSubmit}
          back={() => setFlow("SEARCH")}
          remove={removePlayer}
        />
       }
       {(flow === 'DONE') && 
        <Done 
          orderName={orderName} 
          action={handleDoAnother}
          back={() => navigate(-1)}
        />
        }
      </div>
    </>
  );
}


export default OrderMain;
