import PlayerRow from './PlayerRow';


function PlayersTable({players, remove}) {
  if (!players || players.length === 0) return null;

  return (
    <>
      <div className='players-table-container'>
        {players.map((player,i) => 
          <PlayerRow 
            key={i+player} 
            player={player} 
            remove={remove}
          /> ) 
        }
      </div> 
      <div className='total-row'>
        <div>TOTAL</div>
        <div className='total-amount'>${players.length * 2}.00</div>
      </div>
    </>
  );
}


export default PlayersTable;
