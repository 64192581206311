
function Done({orderName, action, back}) {

  return (
    <>
      <div className='thank-you'>Thanks <span>{orderName}!</span></div>
      <div className='follow-up'>Ethan will follow up with you in school, to confirm your order, and once it is finished.</div>
      <div className="form-button-group">
        <div className='form-button' onClick={back}>Done</div> 
        <div className='form-button primary' onClick={action}>Place Another Order</div>
      </div>
    </>
      
  );
}


export default Done;
