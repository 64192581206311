import { useNavigate } from "react-router-dom";

import tile1 from'./assets/images/tile1.png';
import tile2 from'./assets/images/tile2.png';
import tile3 from'./assets/images/tile3.png';
import tile4 from'./assets/images/tile4.png';



function TeamView() {

  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("/orders");
  }

  return (
    <>
      <div className='landing-title'>TEAM TILES</div>
      <div className='landing-tile-container'>
       <img  src={tile1} alt="tile1"/>
       <img  src={tile2} alt="tile2"/>
       <img  src={tile3} alt="tile3"/>
       <img  src={tile4} alt="tile4"/>
      </div>
      <div className='action-button-container'>
        <div className='action-button primary' onClick={handleCreate}>PLACE ORDER</div>
      </div>
    </>
      
  );
}


export default TeamView;
