import { useState } from 'react';
import PlayersTable from './PlayersTable';


function CheckOut({players, action, back, remove}) {

  const initFormState = {fullName: ''};
  const [inputs, setInputs] = useState(initFormState);

  const handleInput = (e) => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  if (!players || players.length === 0) return null;


  return (
    <>
      <div className="form-field">
        <div className='form-label'>Your Name</div>
        <input autoFocus
          name="fullName"
          type="text" 
          onChange={handleInput} 
          value={inputs.fullName}
          className="form-input"
        />  
      </div>
      <PlayersTable players={players} remove={remove} />
      <div className="form-button-group">
        <div className='form-button' onClick={back}>Back</div> 
        <div className='form-button primary' onClick={() => action(inputs.fullName)}>Submit</div>
      </div>
    </>
      
  );
}


export default CheckOut;
