import db from "./firebaseApp";
import { 
  doc,
  collection,
  addDoc,
  deleteDoc,
  getDocs,
} from 'firebase/firestore'

import { utilMethods } from '../util/utilMethods';



// firebase Firestore helper methods go here... 

export const firestoreMethods = {
  
  getOrders: async () => {
    try {
      const collectionRef = collection(db, "orders");
      const querySnapshot = await getDocs(collectionRef);

      const documentsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return documentsArray;

    } catch (err) {
        console.log("Get Orders Failed: ", err);
        utilMethods.customLog(`Get Orders Failed: ${err}`);
    }
  },

  addOrder: async (order) => {
    try {
      const collectionRef = collection(db, "orders");
      await addDoc(collectionRef, order);
    } catch (err) {
      utilMethods.customLog(`Add Order Failed: ${err}`);
      console.log("Add Order Failed: ", err);
    }
  },

  removeOrder: async (orderId) => {
    try {
      const orderRef = doc(db, "orders", orderId);
      await deleteDoc(orderRef);
    } catch (err) {
      utilMethods.customLog(`Delete Order Failed: ${err}`);
      console.log("Delete Order Failed: ", err);
    }
  },

}