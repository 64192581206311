import { useState, useEffect } from 'react';

import { firestoreMethods } from '../firebase/firestoreMethods';
import { utilMethods } from '../util/utilMethods';


function ViewOrders() {

  const [orders, setOrders] = useState();
  const [refreshOrders, setRefreshOrders] = useState(true);


  // fetch Orders
  useEffect(() => {
    
    let active = true;
    //let teamId = 'fg4XrWbm5zqodbzqgrJI'; // NYR
    const fetchOrders = async () => {
      const newData = await firestoreMethods.getOrders();
      if (active && refreshOrders){
        setOrders(newData);
        setRefreshOrders(false)
      }
    };  

    fetchOrders();
    
    return () => {
      active = false;
    };
  }, [refreshOrders]);

  const removeOrder = async (orderId) => {
    console.log(orderId);
    await firestoreMethods.removeOrder(orderId);
    setRefreshOrders(true);
  }

  const headers = ['REF', 'Date', 'For', 'Players', 'Status', 'ID', ''];
  const headerClasses = ['', '-date', '', '-players', '', '-id', ''];


  if (!orders) return null;

  return (
    <>
      <div className='admin-title'>ADMIN - ORDERS</div>
      <div className='admin-orders-container'>
        <div className='order' >
          {headers.map((header, i) => (
            <div className={'order-field-header order-field'+headerClasses[i]} key={header}>{header}</div>
          ))}
        </div>
        {orders.map((order, index) => (
          <div className='order' key={index}>
            <div className='order-field'>{order.ref}</div>
            <div className='order-field-date'>{utilMethods.formatDate(order.date)}</div>
            <div className='order-field'>{order.for}</div>
            <div className='order-field-players'>
              {order.players.map((player, index) => (
                <div key={player + index}>{player}</div> 
              ))}
            </div>
            <div className='order-field'>{order.status}</div>

            <div className='order-field-id'>{order.id}</div>
            <i className="material-icons-outlined" 
              onClick={() => removeOrder(order.id)}>delete</i>         
          </div>  
        ))}
      </div>
    </>
      
  );
}


export default ViewOrders;
