import tileIcon from'../assets/images/tileIcon.png';


function PlayerRow({player, remove}) {

  return (
    <div className='player'>
      <img className='player-tile-icon' src={tileIcon} alt={`tile-icon-{player}`}/>
      <div className='player-name'>{player}</div> 
      <div className='player-price'>$2.00</div> 
      <i className="material-icons-outlined player-remove" 
        onClick={() => remove(player)}>delete</i>         
    </div>    
  );
}


export default PlayerRow;
